.left {
  text-align: left !important;
  margin: 1rem;
  margin-top: 5rem;
}

.wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}